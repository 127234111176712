.liAddFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100px;
    padding: 5px 10px;
  }
  
  .addFilterSpan {
    color: black;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 25px;
    text-transform: capitalize;
  }
  
  .liAddFilter:hover {
    background-color: #00000024;
  }
  .active {
    background-color: #3b3b3b0f;
  }
  