.container {
  margin-top: 34px;
  padding: 0px 10px;
}
.bodyContainer {
  margin-top: 1em;
}
.filterDaysContainer {
  width: 100%;
  margin: 1em 0;
}
.filter {
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
  width: 240px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}
.moneyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.totalAmount {
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: #000;
}
.coinsProcessed {
  box-shadow: 0 0.07em 0.125em 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 24px;
  background-color: #fdfdfd;
  border: 1px solid #e7e7e7;
  border-radius: 0.25em;
  padding: 15px 18px;
}
.coinsProcessedHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
}
.coinsProcessedHeaderTitleSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.graphic {
  height: 400px;
  max-height: 400px;
  max-width: 98%;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presets {
  display: flex;
  flex-direction: column;
}
.presetElement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
