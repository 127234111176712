.container {
  border: 1px solid #e4e4e4;
  background-color: #f8f8f8;
  border-radius: 10px;
  width: 350px;
  height: 72px;
  display: flex;
  flex-direction: column;
}
.container:hover {
  cursor: pointer;
}
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.ticker {
  font-size: 16;
  line-height: 20px;
  font-weight: 700;
}
.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.headerSubRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.headerSubRight p {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #707070;
}
.circularSelected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #bbbaba;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 40px;
  height: 40px;
}
.allCoins {
}
.tokenChainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.logoChain {
  width: 30px;
  height: 30px;
}
.tokenChain {
  font-size: 18px;
  line-height: 25px;
}
