.textFieldContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px; /* Ajusta según sea necesario */
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .textFieldInput {
    width: calc(100% - 40px);
    padding: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
  }
  
  .textFieldIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; 
  }

  .textFieldClearIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }

  .textFieldIcon svg {
    color: #666;
  }
  